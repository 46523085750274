import './AdminPendingMSPsStyles.scss'
import { Table } from 'react-bootstrap'
import AdminBanner from '../../components/AdminBanner'
import FormCheckbox from '../../components/FormCheckbox'
import Modal from '../../components/Modal'
import FormButton from '../../components/FormButton'
import { adminRoutes } from '../../config/constants'
import { Link } from 'react-router-dom'

const AdminPendingMSPsComponent = ({
  showModal,
  handleCloseModal,
  handleOnChangeWelcomeEmail,
  handleOnChangeFollowUp,
  handleOnChangeCompleted,
  pendingMSPs,
  processedMSPs,
  handleClickEngage,
  loading,
}) => {
  return (
    <>
      <Modal
        showModal={showModal.show}
        handleCloseModal={handleCloseModal}
        title={showModal.title}
        message={showModal.message}
        redirect={showModal.redirect}
        buttonMessage={showModal.buttonMessage}
      />
      <AdminBanner title="PENDING MSPs" />
      <div className="admin-pending-msps-container">
        <div className="admin-pending-msps-table-container">
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Link to={adminRoutes.ADD_MSP_ROUTE}>
            <FormButton
              label={loading ? 'LOADING...' : 'ADD MSP'}
              width={100}
              mdw
            />
            </Link>
          </div>
          <h3 className="admin-pending-msps-title">Pending ({pendingMSPs?.length})</h3>
          <Table className="admin-pending-msps-table" borderless hover>
            <thead>
              <tr>
                <th style={{ width: '20%' }}>MSP</th>
                <th style={{ width: '20%' }}>Primary Contact</th>
                <th style={{ width: '10%' }}>Package</th>
                <th style={{ width: '20%' }}>Payment Method</th>
                <th style={{ width: '10%' }} className="text-center">
                  Welcome Email
                </th>
                <th style={{ width: '10%' }} className="text-center">
                  Followup
                </th>
                <th style={{ width: '10%' }} className="text-center">
                  Completed
                </th>
              </tr>
            </thead>
            <tbody>
              {pendingMSPs?.map(pendingMSP => (
                <tr key={pendingMSP.id}>
                  <td>
                    <Link to={`${adminRoutes.MSPS_ROUTE}/${pendingMSP.msp.mspId}`}>
                      {pendingMSP.msp.companyName}
                    </Link>
                  </td>
                  <td>{`${pendingMSP.msp.pcFirstName} ${pendingMSP.msp.pcLastName}`}</td>
                  <td>{pendingMSP.msp.package.packageSku}</td>
                  <td>{pendingMSP.msp.paymentMethod?.name}</td>
                  <td className="text-center">
                    <FormCheckbox
                      id={pendingMSP.id}
                      onChange={handleOnChangeWelcomeEmail}
                      checked={pendingMSP.welcomeEmail}
                      style={{ marginBottom: 0 }}
                    />
                  </td>
                  <td className="text-center">
                    <FormCheckbox
                      id={pendingMSP.id}
                      onChange={handleOnChangeFollowUp}
                      checked={pendingMSP.followUp}
                      style={{ marginBottom: 0 }}
                    />
                  </td>
                  <td className="text-center">
                    <FormCheckbox
                      id={pendingMSP.id}
                      onChange={handleOnChangeCompleted}
                      checked={pendingMSP.completed}
                      style={{ marginBottom: 0 }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="admin-pending-msps-table-container">
          <h3 className="admin-pending-msps-title">Processed ({processedMSPs?.length})</h3>
          <Table className="admin-pending-msps-table" borderless hover>
            <thead>
              <tr>
                <th style={{ width: '20%' }}>MSP</th>
                <th style={{ width: '20%' }}>Primary Contact</th>
                <th style={{ width: '10%' }}>Package</th>
                <th style={{ width: '20%' }}>Payment Method</th>
                <th style={{ width: '10%' }} className="text-center">
                  Welcome Email
                </th>
                <th style={{ width: '10%' }} className="text-center">
                  Followup
                </th>
                <th style={{ width: '10%' }} className="text-center">
                  Completed
                </th>
              </tr>
            </thead>
            <tbody>
              {processedMSPs?.map(pendingMSP => (
                <tr key={pendingMSP?.id}>
                  <td>
                    <Link to={`${adminRoutes.MSPS_ROUTE}/${pendingMSP.msp.mspId}`}>
                      {pendingMSP.msp.companyName}
                    </Link>
                  </td>
                  <td>{`${pendingMSP?.msp.pcFirstName} ${pendingMSP?.msp.pcLastName}`}</td>
                  <td>{pendingMSP?.msp.package.packageSku}</td>
                  <td>{pendingMSP.msp.paymentMethod?.name}</td>
                  <td className="text-center">
                    <FormCheckbox
                      id={pendingMSP.id}
                      onChange={handleOnChangeWelcomeEmail}
                      checked={pendingMSP?.welcomeEmail}
                      style={{ marginBottom: 0 }}
                    />
                  </td>
                  <td className="text-center">
                    <FormCheckbox
                      id={pendingMSP.id}
                      onChange={handleOnChangeFollowUp}
                      checked={pendingMSP?.followUp}
                      style={{ marginBottom: 0 }}
                    />
                  </td>
                  <td className="text-center">
                    <FormCheckbox
                      id={pendingMSP.id}
                      onChange={handleOnChangeCompleted}
                      checked={pendingMSP?.completed}
                      style={{ marginBottom: 0 }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  )
}

export default AdminPendingMSPsComponent
